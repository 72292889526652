import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import './service-components/i18n'
import 'jquery'
import 'popper.js'
import 'bootstrap'

import Header from './header'
import Footer from './footer'
import LanguageSwitcher from './language-switcher'
import Particles from 'react-particles-js'
import config from './particlesjs-config'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            name
            surname
          }
        }
      }
    `}
    render={data => (
      <div className="h-100">
        <div className="container-fluid position-absolute"
          style={{
            width: '100%',
            top: 0,
            left: 0,
            padding: 0
          }}
        >
          <Header name={data.site.siteMetadata.name} surname={data.site.siteMetadata.surname}/>
          <div className="content">
            {children}
          </div>
          <Footer/>
        </div>
        <div className="position-absolute" style={{
          right: 0,
          top: 0,
          zIndex: 100
        }}>
          <LanguageSwitcher />
        </div>
        <Particles
          className="h-100"
          params={config}
        />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
