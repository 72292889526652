import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-i18next'

const Header = ({ name, surname, t }) => (
  <nav className="container header">
    <div className="row justify-content-center">
      <div className="col-auto">
        <Link to="/" id="brand" className="link">
          <span style={{ marginLeft: '15px' }}>{name}</span>
          <br />
          {surname} <i className="fa fa-keyboard-o" aria-hidden="true" />
        </Link>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-auto">
        <Link to="/contacts/" className="link"> {t('contacts')} </Link>
      </div>
      <div className="col-auto">
        <Link to="/about/" className="link">{t('about')}</Link>
      </div>
    </div>
  </nav>
)

Header.propTypes = {
  name: PropTypes.string,
  surname: PropTypes.string,
}

Header.defaultProps = {
  name: ``,
  surname: ``,
}

export default translate()(Header)
