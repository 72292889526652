import React, { Component } from 'react'
import classNames from 'classnames'
import { translate } from 'react-i18next'

class LanguageSwitcher extends Component {
  constructor (props) {
    super(props)
    const { i18n, t } = this.props
    this.state = { language: i18n.language, t: t }

    this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.i18n.language !== prevState.language) {
      return ({ language: nextProps.i18n.language })
    }
    return prevState
  }

  handleChangeLanguage (lng) {
    const { i18n } = this.props
    i18n.changeLanguage(lng)
  }

  renderLanguageChoice ({ code }) {
    const linkClass = classNames('lang-switcher btn btn-link', {
      'active': this.state.language === code,
    })
    const spanClass = classNames('flag-icon', `flag-icon-${ code === 'en' ? 'gb' : code }`)

    return (
      <a
        key={code}
        className={linkClass}
        onClick={() => this.handleChangeLanguage(code)}
      >
        <span className={spanClass} />
      </a>
    )
  }

  render () {
    const languages = [
      { code: 'en', label: this.state.t('English') },
      { code: 'ru', label: this.state.t('Russian') },
    ]

    return (
      <div>
        {languages.map(language => this.renderLanguageChoice(language))}
      </div>
    )
  }
}

export default translate('LanguageSwitcher')(LanguageSwitcher)
