import React from 'react'
import moment from 'moment'
import { Link } from 'gatsby'
import { translate } from 'react-i18next'

const Footer = ({ t }) => (
  <footer className="footer">
    <div className="row justify-content-start no-gutters">
      <div className="col-md-6">
        <span style={{
          paddingLeft: '20px'
        }}>
         © {moment().format('MMMM Do YYYY')}, Dmytro Maksutov
        </span>
      </div>
      <div className="col-md-6">
        <div className="float-right">
          <ul className="list-inline footer-navigation">
            <li className="list-inline-item">
              <Link to="/" id="brand" className="link">
                {t('home')}
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="/contacts/" className="link"> {t('contacts')} </Link>
            </li>
            <li className="list-inline-item">
              <Link to="/about/" className="link">{t('about')}</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default translate()(Footer)
